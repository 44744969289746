import "./App.css";
import logo from "./images/logo.png";
import sliceone from "./images/Sliceone.png";
import apple from "./images/button_applestore.png";
import google from "./images/button_gp.png";

function App() {
  return (
    <div className="content">
      {/* 头部 */}
      <div className="header">
        {/* 左边 */}
        <div className="left">
          <img src={logo} alt="logo" className="logo"></img>
        </div>

        {/* 右边 */}
        <div className="right">
          <a href="http://www.zhuyingdev.com/TermsofUse.htm">Term of Use</a>
          <a href="http://www.zhuyingdev.com/PrivacyPolicy.htm">
            Privacy Policy
          </a>
          <a href="javascript:;" className="contact">
            Contact us
            {/* 气泡层 */}
            <p className="bubble">
              If you have some problem when using Gocut,you can tell us in
              Instagram or Email us:<br></br>
              <span>hzzhuying123@163.com</span>
            </p>
          </a>
        </div>
      </div>

      {/* 主体 */}
      <div className="main">
        <div className="cld-1">
          <img src={sliceone} alt="sliceone" className="cldImg-1"></img>
          <p>
            GoCut <br></br>Glowing Video Maker
          </p>
          <a href="https://apps.apple.com/us/app/id1522818768">
            <img src={apple} alt="apple" className="apple"></img>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.videoedit.gocut">
            <img src={google} alt="google" className="google"></img>
          </a>
        </div>
        <div className="cld-2"></div>
        <div className="cld-3"></div>
        <div className="cld-4"></div>
      </div>
      {/* 尾部 */}
      <div className="footer">
        <p>Copyright © GoCut 2021.All Rights Reserved</p>
      </div>
    </div>
  );
}

export default App;
